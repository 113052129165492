import React from 'react';
import { Helmet } from 'react-helmet'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import TSParticles404 from '../components/particles404';

import AstronautSvg from '../img/404/astronaut.svg';
import PlanetSvg from '../img/404/planet.svg';

const FourZeroFourPage = () => {
    const { t } = useTranslation();
    return (
        <>
            <Helmet
                bodyAttributes={{
                    class: 'permission_denied'
                }} />

            <TSParticles404 />

            <div class="denied__wrapper">
                <h1>404</h1>
                <h3>{t('404.lost.start')}<span>{t('404.lost.span')}</span>{t('404.lost.end')}</h3>
                <img id="astronaut" src={AstronautSvg} alt=""/>
                <img id="planet" src={PlanetSvg} alt=""/>
                <a href="https://pascaltraumueller.dev"><button class="denied__link">{t('404.goHome')}</button></a>
            </div>

        </>
    )
}

export default FourZeroFourPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;