import React from 'react';
import Particles from "react-tsparticles";
import particlesOptions from "../assets/particles404.json";
import '../styles/404.css'

function TSParticles404() { 
  return (
      <Particles options={particlesOptions}/>
    );
}

export default TSParticles404;
